<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 사용자 관리 --------------------------------------------------------------------------------------------->
    <BCard>
      <BCard header-tag="header" footer-tag="footer">

        <div slot="header">
          <BIconPersonBoundingBox/> <strong> 사용자관리 </strong>
          <div class="card-header-actions">
            <small class="text-muted">사용자 및 계정 정보를 관리합니다.</small>
          </div>
        </div>

        <BRow class="mb-2">
          <BCol sm="4">
            <BInputGroup size="sm">
              <BFormInput size="sm"
                          v-model="newUserId"
                          type="text"
                          :state="newUserId.length > 7"
                          placeholder="사용자 아이디 8~20자"
                          maxlength="20"
                          required/>
              <BButton size="sm" variant="primary" class="mr-3" @click="createUser">
                <BIconPersonBadgeFill/>
                계정 생성
              </BButton>
            </BInputGroup>
          </BCol>
          <BCol>

          </BCol>

          <BCol sm="2" class="text-right">
            <BInputGroup size="sm">
              <BFormSelect size="sm" v-model="numOfRecord" :options="[10,15,20,30,50,100]" style="background-color:#2f303a"/>
              <BInputGroupAppend>
                <BButtonGroup>
                  <BButton size="sm" variant="primary" @click="getUserList"><BIconArrowRepeat/></BButton>
                </BButtonGroup>
              </BInputGroupAppend>
            </BInputGroup>
          </BCol>
        </BRow>

        <BRow>
          <BCol>
            <vue-excel-editor
              v-model="rows"
              ref="excelGrid"
              width="100%"
              class="mb-1"
              :page="numOfRecord"
              :readonly-style="{backgroundColor:'#4F5040'}"
              @update="updateCell"
              @select="selectRecord"
              :localized-label="excelEditorLabel"
              filter-row>
              <vue-excel-column field="_id"                     type="string"   key-field invisible/>
              <vue-excel-column field="userId"                  type="string"   width="100px" label='아이디' sticky readonly />
              <vue-excel-column field="role"                    type="map"      width="70px"  label='권한' :options="roleMap" readonly sticky/>
              <vue-excel-column field="name"                    type="string"   width="70px" label='성명' sticky/>
              <vue-excel-column field="nick"                    type="string"   width="100px" label='표시명' />
              <vue-excel-column field="sts"                     type="map"      width="100px"    label='계정상태' :options="stsMap"/>
              <vue-excel-column field="isApproved"              type="map"      width="100px"    label='사용승인' :options="{true:'승인', false:'미승인'}"/>
              <vue-excel-column field="lastLoginLimit"          type="number"   width="90px"    label='미사용 잠금' />
              <vue-excel-column field="department"              type="string"   width="100px" label='부서' />
              <vue-excel-column field="position"                type="string"   width="100px" label='직책' />
              <vue-excel-column field="grade"                   type="string"   width="100px" label='직급' />
              <vue-excel-column field="email"                   type="string"   width="140px" label='email' />
              <vue-excel-column field="hpNo"                    type="string"   width="120px" label='휴대전화' />
              <vue-excel-column field="phoneNo"                 type="string"   width="120px" label='전화번호' />
              <vue-excel-column field="wgCode"                  type="map"      width="80px"    label='작업그룹' :options="workMap"/>
              <vue-excel-column field="approvedAt"              type="date"     width="130px"    label='승인일시' :to-text="toLocalYmd" readonly/>
              <vue-excel-column field="pwdExpiresAt"            type="date"     width="130px"   label='비밀번호 만료' :to-text="toLocalYmd" readonly/>
              <vue-excel-column field="tokenExpireMin"          type="number"   width="100px"   label='세션시간(분)' />
              <vue-excel-column field="failCount"               type="number"   width="60px"    label='실패 수' readonly/>
              <vue-excel-column field="isLoggedIn"              type="map"      width="60px"    label='로그인' :options="{true:'로그인', false:''}" readonly/>
              <vue-excel-column field="lastLoggedInAt"          type="string"   width="130px"    label='최종로그인' :to-text="toLocalYmd" readonly/>
              <vue-excel-column field="ipAddr"                  type="string"   width="120px"   label='접속IP' readonly/>
              <vue-excel-column field="device"                  type="string"   width="120px"   label='접속기기' readonly/>
              <vue-excel-column field="result"                  type="string"   width="150px"   label='로그인결과' readonly/>
              <vue-excel-column field="reasonLock"              type="string"   width="150px"   label='잠금사유' readonly/>

              <vue-excel-column field="updatedAt"               type="date"     width="130px" label='수정일' :to-text="toLocalYmd" readonly/>
              <vue-excel-column field="updId"                   type="string"   width="100px" label='수정자' readonly/>
              <vue-excel-column field="createdAt"               type="date"     width="130px" label='등록일' :to-text="toLocalYmd" readonly/>
              <vue-excel-column field="regId"                   type="string"   width="100px" label='등록자' readonly/>

            </vue-excel-editor>
          </BCol>
        </BRow>

      </BCard>

      <BCard class="mt-2" v-if="user!==null">
        <div slot="header">
           <strong> <BBadge variant="dark"> <BIconPersonSquare/> {{ user.userId }}</BBadge> 계정 정보 </strong>
          <div class="card-header-actions">
            <small class="text-muted">계정을 관리합니다</small>
          </div>
        </div>
        <BRow class="small">
          <BCol sm="6">
            <BTable small stacked :items="[user]" :fields="userFields">
              <template v-if="this.$store.state.user.role === 'SA'" #cell(role)>
                <BInputGroup>
                  <BFormSelect size="sm" v-model="user.role"
                               style="background-color:#2f303a"
                               :options="roleMap"/>
                  <BButton size="sm" variant="warning"
                           @click="updateRole"> 변경
                  </BButton>
                </BInputGroup>
              </template>
              <template v-else #cell(role)>
                <BInputGroup>
                  <BFormInput size="sm" v-model="roleMap[user.role]"
                              style="background-color:#2f303a"
                              readonly/>
                </BInputGroup>
              </template>
              <template #cell(isInitPwd)="data">
                <BFormSelect size="sm" v-model="user.isInitPwd"
                             style="background-color:#2f303a"
                             @input="setPwdState(data.item)"
                             :options="[{value: true, text: 'Yes'} ,{value:false, text:'No'}]"/>
              </template>
              <template #cell(lastLoginLimit)="row">
                <BInputGroup>
                  <BFormInput size="sm" type="number" v-model="row.value"></BFormInput>
                  <BButton size="sm" variant="warning" @click="setLastLoginLimit(row.value)">수정</BButton>
                </BInputGroup>
              </template>
              <template #cell(allowedIpAddrs)="row">
                <BInputGroup>
                  <BFormSelect size="sm"
                               v-model="selectedIpAddr"
                               style="background-color:#2f303a"
                               :options="row.value"
                               :select-size="3"/>
                  <BButton size="sm" variant="warning"
                           @click="updateIpAddr('del',selectedIpAddr)"> 삭제
                  </BButton>
                </BInputGroup>

                <BInputGroup>
                  <BFormInput size="sm" v-model="inputIpAddr"></BFormInput>
                  <BButton size="sm" variant="primary"
                           @click="updateIpAddr('add',inputIpAddr)"> 추가
                  </BButton>
                </BInputGroup>
              </template>
              <template #cell(isLocked)="data">
                <BFormSelect size="sm" v-model="user.isLocked"
                             style="background-color:#2f303a"
                             @input="setLockState(data.item)"
                             :options="[{value: true, text: 'Yes'} ,{value:false, text:'No'}]"/>
              </template>
              <template #cell(pwdExpiresAt)="data">
                <BFormInput size="sm"
                            v-model="user.pwdExpiresAtLocal"
                            type="date"
                            @change="setPwdExpires(data.item)"
                />
              </template>
              <template #cell(tokenExpireMin)="row">
                <BInputGroup>
                  <BFormInput size="sm"
                              v-model="user.tokenExpireMin"
                              type="number"/>
                  <BButton size="sm" variant="warning" @click="setTokenExpireMin(row.value)">수정</BButton>
                </BInputGroup>

              </template>
            </BTable>

            <div class="text-right">
            <BButtonGroup>
              <BButton size="sm" variant="warning"
                       @click="unlockLogin"
                       v-show="user.isLocked" :disabled="!user.isLocked">
                <BIconUnlockFill/> 계정 잠금 해제
              </BButton>
              <BButton size="sm" variant="warning"
                       @click="passwordReset">
                <BIconLockFill/> 비밀번호 초기화
              </BButton>

              <BButton size="sm"  variant="danger" class="ml-1"
                       @click="deleteRecord">
                <BIconTrashFill/> 선택 계정 삭제
              </BButton>
            </BButtonGroup>
            </div>
          </BCol>
          <BCol sm="6">
            <BRow>
              <BCol sm="8">
                <BInputGroup prepend="이력 조회 일자" size="sm" class="mb-1">
                  <BInputGroupAppend>
                    <BFormInput size="sm" type="date" v-model="historyFromDt"/>
                  </BInputGroupAppend>
                  <BInputGroupAppend>
                    <BFormInput size="sm" type="date" v-model="historyToDt"/>
                  </BInputGroupAppend>
                  <BButton size="sm" variant="info" @click="getUserHistory"><BIconSearch/> 검색</BButton>
                </BInputGroup>
              </BCol>
              <BCol>
              </BCol>
            </BRow>
            <BTable small sticky-header="550px" selectable hover striped dark
                    class="small text-nowrap"
                    thead-class="text-center"
                    ref="histTable"
                    id="histTable"
                    select-mode="multi"
                    selectedVariant="primary"
                    :items="histRows"
                    :fields="histFields"
                    :busy="isHistLoading"/>

          </BCol>
        </BRow>
      </BCard>

    </BCard>



  </div>
</template>

<script>


//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  clone,
} from '@/common/utils';
import qs from 'querystring';
import moment from "moment";
import con, {ExcelEditorLabel} from '@/common/constants';


const _user = {
  userId         : '',
  name           : '',
  nick           : '',
  grade          : '',
  position       : '',
  department     : '',
  email          : '',
  hpNo           : '',
  phoneNo        : '',
  role           : '',
  sts            : 'RG',
  wgCode         : 'DEFAULT',
  hash           : '',
  hashes         : [],
  failCount      : 0,
  isLoggedIn     : false,
  lastLoginLimit : 14,
  lastLoggedInAt : null,
  tokenId        : '',
  ipAddr         : '',
  allowedIpAddrs : '',
  result         : '',
  isInitPwd      : false,
  isLocked       : true,
  isDeleted      : false,
  lockedAt       : null,
  reasonLock     : '',
  pwdExpiresAt   : null,
  tokenExpireMin : 60,
  device         : '',
  salt           : '',
};

const userColMap = {
  userId         : '아이디',
  name           : '성명',
  nick           : '표시명',
  grade          : '직급',
  position       : '직책',
  department     : '부서',
  email          : '이메일',
  hpNo           : '휴대전화번호',
  phoneNo        : '전화번호',
  role           : '권한',
  sts            : '계정상태', // {RG:'최초등록', PW:'비밀번호 변경', PI:'비밀번호 초기화', LK:'잠김' NR:'정상'}
  wgCode         : '워크그룹',
  hash           : '해쉬',
  hashes         : '해쉬이력',
  failCount      : '실패건수',
  isLoggedIn     : '로그인여부',
  lastLoginLimit : '미사용 잠금일 수',
  lastLoggedInAt : '로그인 일시',
  tokenId        : '토큰ID',
  ipAddr         : '접속IP',
  allowedIpAddrs : '허용IP',
  result         : '로그인결과',
  isInitPwd      : '비밀번호초기화 여부',
  isApproved     : '사용여부',
  isLocked       : '계정잠금여부',
  isDeleted      : '계정삭제',
  lockedAt       : '잠금일시',
  reasonLock     : '잠금사유',
  pwdExpiresAt   : '비밀번호 만료일시',
  tokenExpireMin : '토큰만료(분)',
  device         : '접속 디바이스',
  salt           : 'Salt',
  regId          : '등록자', // 등록자
  updId          : '수정자', // 수정자
};

// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };

//----------------------------------------------------------------------------------------------------
export default {
  name: "Users",
  components: {

  },
  data () {
    return {
      con,
      newUserId: '',
      roleMap: {SA:'관리자', SM:'담당자', SS: '사용자'},
      stsMap: {RG:'최초등록', PW:'비밀번호 변경', PI:'비밀번호 초기화', LK:'계정잠김', NR:'정상', DT: '삭제'},
      workMap: {},
      maps: this.$store.state.codeMaps,

      user: null,

      tank : null,
      machineMap: {},
      downloadModalShow: false,

      selectedIpAddr: '',
      inputIpAddr: '',

      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,

      userFields: [
        { key:'role'        , label: 'ROLE'},
        // { key:'isLoggedIn'  , label: '로그인 여부'},
        // { key:'tokenId'     , label: '토큰 아이디'},
        { key:'allowedIpAddrs', label: '허용 IP'},
        { key:'tokenExpireMin', label: '인증유효시간(분)' },
        { key:'pwdExpiresAt', label: '비밀번호 만료일시', formatter: v=>{return (v? moment(v).format('YYYY-MM-DD HH:mm:ss'):'')} },
        { key:'lastLoginLimit', label: '미사용 잠금 일수'},
        { key:'lastLoggedInAt', label: '최종 로그인 일시', formatter: v=>{return (v?moment(v).format('YYYY-MM-DD HH:mm:ss'):'')} },
        { key:'failCount'   , label: '로그인 실패 횟수'},
        { key:'result'      , label: '메세지'},
        { key:'ipAddr'      , label: '접속 IP'},
        { key:'isApproved'  , label: '사용 승인'},
        { key:'isInitPwd'   , label: '비밀번호 초기화'},
        { key:'isLocked'    , label: '계정 잠김'},
        { key:'lockedAt'    , label: '잠금 시각', formatter: v=>{return (v?moment(v).format('YYYY-MM-DD HH:mm:ss'):'')} },
        { key:'reasonLock'  , label: '잠금 사유'},
        { key:'device'      , label: '접속 디바이스 정보'},
        { key:'updatedAt'   , label: '변경일시', formatter: v=>{return (v? moment(v).format('YYYY-MM-DD HH:mm:ss'):'')} },
      ],

      historyFromDt:  moment().add(-1, 'days').format('YYYY-MM-DD'),
      historyToDt:  moment().format('YYYY-MM-DD'),
      histRows: [],
      histFields: [
        { key:'createdAt'   , label: '등록일시', sortable:true, formatter: v=>{return (v? moment(v).format('YY/MM/DD HH:mm'):'')} },
        { key:'result'      , label: '메세지', sortable:true},
        { key:'failCount'   , label: '로그인 실패 횟수', sortable:true },
        { key:'ipAddr'      , label: '접속 IP', sortable:true },
        { key:'role'        , label: '역할', sortable:true },
        { key:'isInitPwd'   , label: '비밀번호 초기화', sortable:true },
        { key:'tokenExpireMin', label: '세션유지시간', sortable:true },
        { key:'isLocked'    , label: '계정 잠김', sortable:true },
        { key:'reasonLock'  , label: '잠금 사유', sortable:true },
        { key:'device'      , label: '접속 디바이스 정보', sortable:true },
      ],
      isHistLoading: false,


    }

  },
  created(){
    try{
      console.log("--- user-mng  created ---------------------");

    }catch(err){
      console.log(err);
    }
  },

  computed: {
    excelEditorLabel() {
      return ExcelEditorLabel
    }
  },

  async mounted() {
    console.log("--- user-mng mounted -----------------------");
    await this.getWorkMap();
    await this.getUserList();
    // below is not work!
  },

  methods: {

    async createUser(){
      let newUser;
      try{
        if(!this.newUserId) return alert("사용자 아이디 입력 필요");

        if( !await this.confirmModal(`사용자 계정을 생성합니다. 진행 하시겠습니까?`, '사용자 계정 등록') ){
          await this.alertModal( '계정 등록 취소', '사용자 계정 등록');
          return;
        }else{
          newUser = clone(_user);
        }

        newUser.userId = this.newUserId;

        const r = await apiCall('post', `/api/user`, newUser);

        if( r.code===200){
          await this.alertModal( `계정등록(비밀번호: '${r.result.initPwd}')`, '계정등록 완료', 'success');
          this.newUserId = '';
          await this.getUserList();
        }else{
          await this.alertModal('등록 실패: '+r.message,'사용자 계정 등록', 'warning');
        }

      }catch(err){
        console.log( err );
        await this.alertModal('등록 실패:'+err.message,'사용자 계정 등록', 'danger');
      }

    },

    async getUserList(){
      try{
        let qry = '';
        this.paramMap = {};
        if(this.searchWord) this.paramMap[this.searchField] = this.searchWord;
        qry = qs.stringify( this.paramMap );
        console.log( "query-string ---------- ", qry );

        this.rows = [];
        const r = await apiCall('get', `/api/user`);

        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`${this.rows.length}건 조회됨`, 'info');
        }

        if(this.$refs['excelGrid']) this.$refs['excelGrid'].clearFilter();
        this.user = null;

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }

    },

    async getWorkMap(){
      try{
        this.workMap = {};
        const r = await apiCall('get', `/api/workgroup`);
        for( let m of r.result ){
          this.workMap[m.wgCode] = `${m.wgCode}-${m.name}`;
        }
      }catch(err){
        console.log( err );
      }
    },

    async selectRecord(idx, evt){
      if(!evt){
        // this.tankFormShow = false;
        this.user = null;
        return;
      }
      this.user = this.$refs['excelGrid'].getSelectedRecords().pop();
      console.log( 'select idx, user --->', idx, this.user );

      this.user['pwdExpiresAtLocal'] = moment( this.user.pwdExpiresAt ).format('YYYY-MM-DD');
      await this.getUserHistory();
    },


    async updateCell(rec){
      let rc = rec.pop();
      if(!rc.keys[0]){
        alert( "KEY_NOT_FOUND" );
        return;
      }

      console.log( "updateRecord--------- record.$id--->",rc.$id );
      console.log( "updateRecord--------- record.keys[0]--->",rc.keys[0] );
      // console.log( "updateRecord--------- rec[0]|rc --->",rc );
      // const tIndex = this.$refs['excelGrid'].rowIndex[rc.$id];
      // console.log( "updateRecord--------- rowIndex--->",tIndex );
      // let row = this.$refs['excelGrid'].table[tIndex];
      //
      // console.log( "row ---------> ", row );

      let r = null;

      let param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      param[fieldName] = rc.newVal;
      try{
        // const row = this.$refs['excelGrid'].currentRecord;
        console.log( 'param --->', param);
        r = await apiCall("PUT", `/api/user/${objectId}`, param);
        // console.log( r );
        await this.toastResult(r, `${userColMap[fieldName]} 수정`);
      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.log( err );
      }

    },

    async deleteRecord(recs){
      // let r = null, msg = null, notice = null;
      try{

        let params = this.$refs['excelGrid'].getSelectedRecords();
        this.progCount = 0;
        this.totalCount = params.length;
        if(!params.length) return this.toastInfo('[선텍된 레코드 없음] 좌측의 번호를 클릭하여 레코드 선택');

        console.log( "deleteRecord --- recs ---", recs.length );
        console.log( "deleteRecord --- params ", params );
        console.log( "deleteRecord length ----> ", params.length );

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없으며 시스템 장애가 발생할 수 있습니다. 진행 하시겠습니까?`;

        if( !(await this.confirmModal(confirmMsg, '레코드 삭제')) ){
          return;
        }

        let failCnt = 0;
        for( let record of params){
          console.log( "deleteRecord for --------->", record );
          const tIndex = this.$refs['excelGrid'].rowIndex[record.$id];
          const r = await apiCall('DEL', `/api/user/${record._id}`);
          console.log( r );
          if( r.code===200 ){
            this.progCount++;
            this.$refs["excelGrid"].deleteRecord(tIndex);
          }else{
            failCnt++;
          }
        }

        await this.alertModal(`삭제: ${this.progCount}건, 실패: ${failCnt}`, '레코드 삭제결과', 'warning');
        this.$refs['excelGrid'].clearAllSelected();
        await this.getUserList();

      }catch(err){
        console.log( err );
      }
    },
    async updateRole(){
      console.log( 'update-role');

      try{
        if(!this.user) {
          await this.toastWarn(`사용자를 선택 하세요`);
          return false;
        }
        const r = await apiCall('put', `/api/auth/role-change/${this.user._id}`,
          {role: this.user.role});

        if(r.code===200){
          await this.toastInfo(  `계정 권한 정보가 변경 되었습니다.`);
        }else{
          await this.toastWarn(  `${r.message}`, 'danger', '권한 변경 실패');
        }
      }catch(err){
        console.error( err );
        await this.alertError( err );
      }

    },
    async updateIpAddr(mode, ip){
      // console.log(ip);
      try{
        if(!this.user) {
          await this.toastWarn(`사용자를 선택 하세요`);
          return false;
        }
        const r = await apiCall('put', `/api/auth/allowed-ip-addr/${mode}/${this.user._id}`, {ipAddr: ip});
        console.log( "setIpAddr--------------",  r.result );
        if(r.code===200){
          this.user.allowedIpAddrs = r.result;
          this.selectedIpAddr = '';
          this.inputIpAddr = '';
          await this.toastInfo(  `접근 허용 IP 정보가 변경 되었습니다.`);
        }else{
          await this.toastWarn(  `${r.message}`, 'danger', '접근 허용 IP 변경 실패');
        }
      }catch(err){
        console.error( err );
        await this.alertError( err );
      }
    },

    async setPwdState(item){
      console.log( 'setPwdState item--->', item);
      try{
        if(!this.user) return alert("사용자를 선택하세요");
        const r = await apiCall('put', `/api/auth/pwd-state/${this.user._id}`, {state: item.isInitPwd});
        console.log( "setPwdState--------------",  r.result );
        if(r.code===200){
          this.isUserLocked = false;
          await this.toastInfo(  `${this.user.userId} 사용자 비밀번호 초기화 상태 변경됨`, 'success');
        }else{
          await this.toastWarn( `${this.user.userId} 사용자 비밀번호 초기화 상태 변경 실패(${r.message})`);
        }
      }catch(err){
        console.error( err );
        await this.alertError( err );
      }
    },

    async setLockState(item){
      console.log( 'setLockState item--->', item);
      try{
        if(!this.user) return alert("사용자를 선택하세요");
        const r = await apiCall('put', `/api/auth/lock-state/${this.user._id}`, {state: item.isLocked});
        console.log( "setLockState--------------",  r.result );
        if(r.code===200){
          this.isUserLocked = false;
          await this.toastInfo( `${this.user.userId} 사용자 계정 상태 변경됨`);
        }else{
          await this.toastWarn( `${this.user.userId} 사용자 계정 상태 변경 실패(${r.message})`);
        }
      }catch(err){
        console.error( err );
        await this.alertError( err );
      }

    },
    async setPwdExpires(item){
      console.log( 'setPwdExpires item--->', item);
      try{
        if(!this.user) return alert("사용자를 선택하세요");
        const r = await apiCall('put', `/api/auth/pwd-expires/${this.user._id}`, {pwdExpiresAt: item.pwdExpiresAtLocal});
        console.log( "setLockState--------------",  r.result );
        if(r.code===200){
          await this.toastInfo(  `${this.user.userId} 사용자 비밀번호 만료일 변경됨`);
        }else{
          await this.toastWarn(  `${this.user.userId} 사용자 비밀번호 만료일 변경 실패(${r.message})`);
        }
      }catch(err){
        console.error( err );
        await this.alertError(err);
      }
    },
    async setLastLoginLimit(val){
      console.log( 'setLastLoginLimit value--->', val);
      try{
        if(!this.user) return alert("사용자를 선택하세요");
        const r = await apiCall('put', `/api/auth/last-login-limit/${this.user._id}`, {lastLoginLimit: val});
        if(r.code===200){
          await this.toastInfo( `${this.user.userId} 사용자 미사용 잠금 설정 일수 변경됨`);
        }else{
          await this.toastWarn( `${this.user.userId} 사용자 사용자 미사용 잠그 설정 일수 변경 실패(${r.message})`);
        }
      }catch(err){
        console.error( err );
        await this.alertError(err);
      }
    },

    async setTokenExpireMin(val){
      try{
        if(!this.user) return alert("사용자를 선택하세요");
        const r = await apiCall('put', `/api/auth/token-expire-min/${this.user._id}`, {tokenExpireMin: val});
        if(r.code===200){
          await this.toastInfo( `${this.user.userId} 사용자 토큰인증 유효시간 변경됨`);
          if(this.$store.state.user._id === this.user._id){
            await this.$store.dispatch('refreshToken');
          }
        }else{
          await this.toastWarn(  `${this.user.userId} 사용자 토큰인증 유효시간 실패(${r.message})`);
        }
      }catch(err){
        console.error( err );
        await this.alertError(err);
      }
    },
    async unlockLogin(){
      try{
        if(!this.user) return alert("사용자를 선택하세요");
        const r = await apiCall('put', `/api/auth/unlock-login/${this.user._id}`);
        console.log( "unlockLogin--------------",  r.result );
        if(r.code===200){
          this.isUserLocked = false;
          await this.toastInfo(  `${this.user.userId} 사용자 로그인 잠금 해제 완료`);
        }else{
          await this.toastWarn(  `${this.user.userId} 사용자 로그인 잠금 해제 실패(${r.message})`);
        }
      }catch(err){
        console.error( err );
        await this.alertError(err);
      }
    },

    async passwordReset(){
      try{
        if(!this.user) return alert("사용자를 선택하세요");

        if( !(await this.confirmModal( `${this.user.userId}의 비밀번호를 초기화 합니다. 진행 하시겠습니까?`, '비밀번호 초기화')) ) return;

        const r = await apiCall('post', `/api/auth/pwd-reset`, {userId: this.user.userId});
        console.log( "password reset --------------",  r.result );
        if(r.code===200){
          await this.toastInfo(  `${this.user.userId} 비밀번호 초기화 완료`);
          // alert( `${this.user.userId}의 임시 비밀번호: [${r.result.initPwd}]\n로그인 시 비밀번호 변경 필요`);
          await this.alertModal( `임시비밀번호: '${r.result.initPwd}'`, '비밀번호 재설정', 'success');
        }else{
          await this.toastInfo( `${this.user.userId} 비밀번호 초기화 실패(${r.message})`);
        }
      }catch(err){
        console.error( err );
        await this.alertError(err);
      }
    },
    async getUserHistory(){
      try{
        const userId = this.user.userId;
        this.histRows = [];
        this.isHistLoading = true;
        let query = ''
        if( this.historyFromDt ){
          query=`?fromDt=${this.historyFromDt}&toDt=${this.historyToDt}`
        }
        const r = await apiCall('get', `/api/user/history/${userId}${query}`);
        // console.log("getUserHistory --->", r)
        if(r.code===200) this.histRows = r.result;
        else  await this.toastResult(r);
      }catch(err){
        console.error( err );
      }finally{
        this.isHistLoading = false;
      }
    },





  }
}
</script>
<style>
.v-scroll-button {
  background-color: #888 !important;
  display: block !important;
  width: 12px;
  border-radius: 6px;
}

.v-scroll-button .runner {
  display: block !important;
}

.vue-excel-editor .table-content .systable .center-text .table-col-header {
  pointer-events: none;
}
</style>
